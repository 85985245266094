.infoFormBox {
  width: 80%;
}
.header {
  height: 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  position: relative;
}
.title {
  font-size: 14px;
  font-weight: 500;
  margin-left: 4%;
}
.btnEdit {
  position: absolute;
  right: 16px;
}
.tableBox {
  margin-top: 20px;
  width: 90%;
}
.tableBox th {
  text-align: right;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 4px;
  width: 10%;
}
.tableBox tr {
  height: 50px;
}
.tableBox td {
  /* width: 100%; */
  padding-left: 20px;
}
.flexBox {
  flex: 1;
}
.ulFlex {
  display: flex;
}
.input {
  /* width: 100%; */
  /* background-color: #fff;
    border: none; */
  font-size: 16px;
  outline-color: var(--specialGrey);
  margin-bottom: 2px;
  list-style: none;
  word-break: break-all;
}
.ulFlex li {
  width: 50%;
}
.reviewTitle {
  font-size: 20px;
  margin-bottom: 5px;
  font-weight: 500;
  text-align: center;
}
.subTitle {
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 32px;
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
}
@media screen and (min-width: 768px) and (max-width: 991px) {
}
@media screen and (max-width: 767px) {
  .infoFormBox {
    width: 100%;
  }
  .input {
    margin-left: 5%;
    font-size: 14px;
  }
  .tableBox th {
    text-align: right;
    font-size: 15px;
    font-weight: 500;
  }
  .subTitle {
    margin-bottom: 15px;
  }
}
