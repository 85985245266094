.flexBox {
  width: 100%;
  display: flex;
}
.passBox {
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  /* align-items: center; */
  justify-content: center;
}
.invisibleBox {
  width: 254px;
  margin-right: 10px;
}
.passImg {
  width: 32%;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  margin-top: 10px;
}
.pass {
  width: 62%;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  margin-top: 47px;
  display: flex;
  flex-direction: column;
  padding-top: 26px;
  padding-bottom: 22px;
  padding-left: 100px;
  padding-right: 100px;
  margin: 40px auto;
  border-radius: 20px;
}
.logo {
  height: 96px;
}
.title {
  text-align: center;
  margin-top: 21px;
  font-size: 32px;
  font-weight: 500;
}
.subtitle {
  text-align: center;
  margin-top: 7px;
  font-size: 14px;
  font-weight: 500;
}
.validFromBox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
}
.validFrom {
  font-size: 16px;
  font-weight: 500;
  margin-right: 5px;
}
.validFromItems {
  font-weight: 500;
}
.validToBox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 7px;
}
.infoBox {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
.info {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}
.name {
  font-size: 24px;
  font-weight: 600;
  margin-right: 5px;
}
.nameitem {
  font-size: 24px;
  font-weight: 500;
}
.contentTitle {
  font-weight: 600;
  font-size: 16px;
  margin-right: 5px;
}
.content {
  font-weight: 500;
}
.boxLeft {
  padding-top: 10px;
}

.btnShare {
  width: 254px;
  margin-right: 0px;
  margin-bottom: 10px;
}
.btnDownload {
  width: 254px;
}
.footerTitle {
  font-size: 13px;
  text-align: center;
  margin-top: 24px;
}
.passForm {
  width: 38%;
  margin-left: 2%;
  margin-top: 40px;
}
.btnBox {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 10px;
}
@media screen and (min-width: 1200px) {
  .infoBox {
    flex-wrap: wrap;
  }
  .info {
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .infoBox {
    flex-wrap: wrap;
  }
  .info {
    flex-wrap: wrap;
    width: 243px;
    height: 32px;
  }
  .validFromBox {
    min-width: 369px;
  }
  .passImg {
    width: 40%;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    margin-top: 10px;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .infoBox {
    flex-direction: column;
    align-items: center;
  }

  .flexBox {
    flex-direction: column;
    align-items: center;
  }
  .passForm {
    order: 1;
  }
  .passForm {
    width: 80%;
    margin-left: 2%;
    margin-top: 40px;
  }
  .btnShare {
    width: 200px;
    display: flex;
    justify-content: center;
  }
  .btnDownload {
    width: 200px;
    display: flex;
    justify-content: center;
  }
  .passBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .btnBox {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 0px;
    margin-top: 10px;
  }
  .invisibleBox {
    display: none;
  }
  .passImg {
    width: 50%;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    margin-top: 10px;
  }
}
@media screen and (max-width: 767px) {
  .pass {
    box-sizing: border-box;
    width: 100%;
    padding-top: 10px;
    padding-left: 5px;
    padding-right: 5px;
    margin: 0;
    border-radius: 0px;
  }
  .infoBox {
    flex-direction: column;
    align-items: center;
  }
  .logo {
    height: 86px;
  }
  .title {
    font-size: 20px;
  }
  .subtitle {
    margin-top: 7px;
    font-size: 13px;
  }
  .qrCode {
    height: 150px;
  }
  .validFromBox {
    flex-wrap: wrap;
  }
  .validToBox {
    flex-wrap: wrap;
  }
  .btnBox {
    width: 200px;
  }
  .btnShare {
    width: 254px;
  }
  .validFrom {
    font-size: 14px;
  }
  .validFromItems {
    font-size: 14px;
  }
  .name {
    font-size: 18px;
  }
  .nameitem {
    font-size: 18px;
    font-weight: 500;
  }
  .contentTitle {
    font-weight: 600;
    font-size: 14px;
    margin-right: 5px;
  }
  .content {
    font-weight: 500;
  }
  .flexBox {
    flex-direction: column;
    align-items: center;
  }
  .passForm {
    order: 1;
  }
  .passForm {
    width: 80%;
    margin-left: 2%;
    margin-top: 40px;
  }
  .btnShare {
    width: 200px;
    display: flex;
    justify-content: center;
  }
  .btnDownload {
    width: 200px;
    display: flex;
    justify-content: center;
  }
  .passBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .btnBox {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 0px;
    margin-top: 10px;
  }
  .invisibleBox {
    display: none;
  }
  .passImg {
    width: 100%;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
    border-radius: 0px;
    margin-top: 0px;
  }
}
