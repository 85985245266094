.infoFormBox {
    width: 80%;
    display: flex;
    margin: auto;
}

.inputShortBox {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.inputShort {
    width: 48%;
    margin-top: 10px;
}

.inputLong {
    margin-top: 16px;
    width: 100%;
}

.numberBox {
    display: flex;
    align-items: center;
    margin-top: 30px;
    justify-content: space-between;
}

.alert {
    font-size: 14px;
    padding: 5px 5px;
    margin-top: 10px;
    line-height: 150%;
}

@media screen and (min-width: 1200px) {}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .inputShortBox {
        flex-direction: column;
    }

    .inputShort {
        width: 100%;
        margin-top: 10px;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {}

@media screen and (max-width: 767px) {
    .inputShortBox {
        flex-direction: column;
    }

    .inputShort {
        width: 100%;
        margin-top: 10px;
    }

    .btnDown {
        margin-left: 3%;
        margin-right: 2%;
    }

    .infoFormBox {
        width: 100%;
    }

    .header {
        font-size: 12px;
    }

    .btnRemove {
        position: absolute;
        right: 3%;
    }

    .inputShort select {
        height: 28px;
    }
}