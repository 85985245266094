.box__mainTitleRight {
  flex: 0.15;
  max-width: 424px;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 34px;
  margin-left: 30%;
}

.box__subTitle {
  display: flex;
  flex: 0.05;
  max-width: 297px;
  margin-bottom: 20px;
  margin-left: 30%;
  line-height: 150%;
}
.box__inputLong {
  display: flex;
  flex: 0.1;
  margin-bottom: 20px;
  margin-left: 30%;
}
.box__inputField {
  flex: 0.7;
  max-width: 337px;
  min-width: 200px;
}
.box__btnRight {
  display: flex;
  flex: 0.1;
  justify-content: flex-end;
  margin-left: 30%;
  margin-top: 60px;
  max-width: 337px;
}
.alert {
  display: flex;
  margin-left: 30%;
}
.alertBox {
  flex: 0.7;
  min-width: 200px;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .box__inputField {
    flex: 1;
  }
  .alertBox {
    flex: 1;
    min-width: 200px;
  }
}
@media screen and (max-width: 767px) {
  .box__mainTitleRight {
    margin-left: 20px;
  }
  .box__subTitle {
    margin-left: 20px;
  }
  .box__inputLong {
    margin-left: 20px;
  }
  .box__btnRight {
    justify-content: flex-start;
    margin-left: 20px;
  }
  .alert {
    display: flex;
    margin-left: 20px;
  }
  .alertBox {
    max-width: 337px;
    min-width: 200px;
  }
}
