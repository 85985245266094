* {
  margin: 0;
  padding: 0;
}
.container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 320px;
}
.box {
  width: 82%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 200px;
  border-radius: 20px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
  padding-bottom: 40px;
}
.formBox {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.subHeader {
  width: 100%;
  display: flex;
  align-items: center;
  height: 103px;
  border-radius: 20px 20px 0 0;
}
.circle {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin-left: 45px;
  margin-right: 17px;
}
.title {
  font-size: 35px;
  font-weight: 500;
}
.alert {
  width: 200%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.notBuyPass {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  padding-top: 200px;
  padding-bottom: 200px;
}
.here {
  margin-left: 5px;
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .formBox {
    width: 64%;
  }
  .box {
    width: 98%;
  }
  .alert {
    width: 156.25%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .subHeader {
    border-radius: 0;
  }
  .box {
    width: 100%;
    display: flex;
    margin-top: 230px;
    border-radius: 0px;
  }
  .formBox {
    width: 80%;
  }
  .alert {
    width: 125%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 767px) {
  .circle {
    width: 16px;
    height: 16px;
    margin-left: 20px;
  }
  .title {
    font-size: 20px;
    font-weight: 500;
  }
  .subHeader {
    border-radius: 0;
  }
  .box {
    width: 100%;
    display: flex;
    margin-top: 20px;
    border-radius: 0px;
  }
  .box {
    width: 100%;
    display: flex;
    margin-top: 44px;
    border-radius: 0px;
    flex-direction: column;
  }
  .formBox {
    width: 80%;
  }
  .alert {
    width: 125%;
  }
  .notBuyPass {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    padding-top: 100px;
    padding-bottom: 200px;
  }
}
