.infoBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  font-size: 20px;
  margin-bottom: 5px;
  font-weight: 500;
}
.subTitle {
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 20px;
}
.wristbandBox {
  padding: 18px;
  margin-top: 10px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.wrstbandTitle {
  font-size: 16px;
  margin-bottom: 20px;
}
.wristbandSubtitile {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 150%;
}
.wristbandAlert {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 150%;
  color: var(--mainColor);
}
.numberTitle {
  font-size: 20px;
  margin-bottom: 10px;
}
.numberBox {
  flex-direction: column;
  display: flex;
  width: 178px;
}
