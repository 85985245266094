* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 320px;
}

.box {
  width: 82%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 150px;
  border-radius: 20px;
  /* box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25); */
  padding-bottom: 40px;
  max-width: 800px;
}

.subHeader {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 103px;
  border-radius: 20px 20px 0 0;
  padding-right: 20px;
}
.passForm {
  width: 100%;
  padding: 0px 0px;
}
.mainBox{
  margin-top:20px;
}
.textBox {
  display: flex;
  align-items: center;
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin-left: 45px;
  margin-right: 17px;
}

.dangerDiv {
  padding: 16px;
  border-radius: 4px;
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  font-size: 14px;
  margin: 16px 0;
}

.subHeaderTitle {
  font-size: 35px;
  font-weight: 500;
  margin: 0;
}

.innerContainer {
    display: flex;
    flex: 0.8 1;
    flex-direction: row;
    border-radius: 20px;
    margin-top: 180px;
    min-width: 320px;
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
    padding: 0px;
    background-color: #fff;
}

.innerContainerWrapper {
    padding: 40px;
    width: calc(75% + 120px);
    margin: auto;
}

.container {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-width: 320px;
}

.innerContainer {
    display: flex;
    flex: 0.8 1;
    flex-direction: row;
    margin-top: 180px;
    min-width: 320px;
}

.row {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}
.box_element {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  /* Adjust as needed */
  flex: 1;
  /* Allow the fields to take equal space */
}
.box__inputField {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  /* Adjust as needed */
  flex: 1;
  margin-left:10px;
  /* Allow the fields to take equal space */
}
.noMargin{
  margin: 0;
}

.label {
  /* margin-bottom: 8px; */
  margin-left: 0px;
  /* Space between label and input box */
  font-size: 14px;
  /* Adjust as needed */
  color: #333;
  /* Adjust as needed */
}

.col3 {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 25%;
    flex: 0 0 25%;
}

.col6 {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 50%;
    flex: 0 0 50%;
}

.col12 {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 100%;
    flex: 0 0 100%;
}

.col3 label,
.col6 label {
    display: block;
}

.col3 input,
.col6 input {
    padding: 10px;
    background-color: #F9F9FB;
    max-width: 100%;
    border: 1px solid #EFEFEF;
    width: 100%;
    display: block;
}

.col3 input:focus,
.col6 input:focus {
    outline: 0;
    border-color: var(--mainColor);
}

.col6>div,
.col3>div {
    width: 100%;
}

label {
    margin-bottom: 5px;
    display: inline-block;
}

.formGroup {
    margin-bottom: 20px;
}

.links {
    /* padding: 0px 15px; */
    margin: 15px 0px 20px;
}

.links a {
    color: #555;
    margin-right: 5px;
    display: inline-block;
}

.cardBox {
    border: 1px solid #EFEFEF;
    padding: 15px;
    border-radius: 4px;
    margin-bottom: 10px;
}

.cardBox h5 {
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 10px;
}

.cardBox p {
    font-size: 16px;
    line-height: 1.5;
}

.cardBox label {
    color: #6F6F6F;
    font-size: 14px;
}

.cardBox p {
    font-size: 16px;
    font-weight: 500;
}

hr {
    margin: 10px 0px;
    background-color: #EFEFEF;
    border: none;
    height: 1px;
}

.cardBox h4 {
    font-size: 16px;
    margin-bottom: 10px;
}

.hasRow .row {
    margin-left: -15px;
    margin-right: -15px;
    width: auto;
}

.mb0 {
    margin-bottom: 0px !important;
}

.flexItem {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.flexHeader {
    margin-bottom: 10px;
}

.btnWrapper {
    text-align: right;
    margin-left: auto;
}

.btnWrapper button {
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
}

.flexItem+p {
    margin-bottom: 20px;
}
.horizontalLine {
  margin: 15px 0 10px 0;
  border-top: 1px solid #EFEFEF;
}
.buttonContainer {
  display: flex;
  justify-content: flex-end;
  /* Aligns the button to the right */
  padding: 10px;
  /* Optional: Add some padding if needed */
}
.button {
  padding: 15px 20px;
  /* Increases the size of the button */
  font-size: 20px;
  /* Increases the font size */
  background-color: #115A84;
  /* Changes the button color */
  color: white;
  /* Button text color */
  border: none;
  border-radius: 10px;
  /* Optional: Rounds the corners */
  cursor: pointer;
}


.paymentContainer {
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif;
}

.poweredByStripe {
  font-size: 13px;
  color: #78909c;
  margin-bottom: 20px;
}

.stripeText {
  font-weight: 500;
}

label {
  display: block;
  font-size: 14px;
  color: "#00000";
  margin-bottom: 8px;
}

.cardRow {
  display: flex;
  gap: 12px;  /* Space between card elements */
}

.cardElementContainer {
  background: #f8f9fa;
  border: 1px solid #e3e8ee;
  border-radius: 4px;
  padding: 10px 12px;
}


.cardElementContainer input {
  width: 100%;
  padding: 0;
  font-size: 16px;
  color: #32325d;
  background: transparent;
  border: none;
  outline: none;
}

.cardElementContainer input::placeholder {
  color: #aab7c4;
}

/* Stripe Element Specific Styles */
:global(.StripeElement) {
  height: 20px;
}

:global(.StripeElement--focus) {
  background: white;
}

:global(.StripeElement--invalid) {
  border-color: #fa755a;
}

:global(.StripeElement--webkit-autofill) {
  background: transparent !important;
}

@media screen and (min-width: 1200px) {}

@media screen and (min-width: 992px) and (max-width: 1199px) {}

@media screen and (min-width: 768px) and (max-width: 991px) {
  
}

@media screen and (max-width: 768px) {}
@media screen and (max-width: 767px) {
  .blockRow {
    display: block;
  }
  .box__inputField {
    margin-left: 0;
    margin-right: 10px;
  }
  .cvvContainer {
    margin: 0;
  }
  .box {
    margin-top: 130px;
    width: 100%;
  }
  .subHeaderTitle {
    font-size: 25px;
    font-weight: 500;
  }
  .paymentPage h2 {
    font-size: 20px;
  }

  .innerContainer {
    padding: 0px;
  }

  .col3,
  .col6 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .cardBox h5 {
    font-size: 12px;
  }

  .cardBox p {
    font-size: 14px;
  }

  .cardBox p {
    font-size: 14px;
  }

  .innerContainer {
    margin-top: 100px;
  }
  .passForm {
    max-width: 90%;
  }
  .buttonContainer {
    justify-content: center;
  }
  .button {
    width: 100%;
    max-width: 300px;
  }
}
