.infoFormBox {
  width: 80%;
}
.header {
  height: 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  position: relative;
}
.inputShortBox {
  display: flex;
  justify-content: space-between;
}
.inputBox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  /* flex-direction: column; */
}
.inputShort {
  width: 48%;
  margin-top: 10px;
  /* margin-right: 2%; */
}
.inputBox :nth-child(even) {
  margin-right: 0;
}

.inputShort select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url(../../assets/dropDown.svg) no-repeat scroll right center transparent;
  padding-right: 23px;
  text-indent: 5px;
  cursor: pointer;
}
/* .inputLong {
    margin-top: 16px;
} */
.numberBox {
  display: flex;
  align-items: center;
  margin-top: 30px;
  justify-content: space-between;
}
.btnDown {
  margin-left: 16px;
  margin-right: 10px;
}
.btnRemove {
  font-size: 12px;
  position: absolute;
  right: 16px;
}
.tableContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
}
.tableBox {
  width: 100%;
  margin-top: 20px;
}
.tdBox {
  width: 52%;
  font-weight: 500;
  font-size: 14px;
}
.dateBox {
  font-weight: 500;
  font-size: 14px;
}
.dateValue {
  height: 34px;
  width: 100%;
  padding-top: 8px;
}
.dateInput svg {
  margin: 0;
}
.checkBox {
  margin-top: 20px;
}
.checkBox input {
  margin-right: 10px;
}
.view {
  margin-left: 3%;
}
.alert {
  font-size: 14px;
  padding: 5px 5px;
  margin-top: 10px;
  line-height: 150%;
}
.noticeBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 40px;
}
.popUpContent {
  text-align: center;
  line-height: 150%;
  font-weight: 500;
}
.noticeBtn {
  width: 254px;
  margin-top: 40px;
}

.dateInput {
  box-sizing: border-box;
  border-radius: 100px;
  border: 1px solid var(--specialGrey);
  height: 34px;
  overflow: hidden;
  width: 100%;
  outline-style: none;
  margin-top: 8px;
  padding-left: 15px;
  padding-right: 5px;
  background: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dateInput svg {
  width: 20px;
  height: 20px;
}
.disable {
  cursor: not-allowed;
  pointer-events: none;
  background-color: #f8f5f5;
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .inputShortBox {
    flex-direction: column;
  }
  .inputShort {
    width: 100%;
    margin-top: 10px;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .noticeBtn {
    width: 200px;
    margin-top: 40px;
  }
}
@media screen and (max-width: 767px) {
  .inputShortBox {
    flex-direction: column;
  }
  .inputShort {
    width: 100%;
    margin-top: 10px;
  }
  .btnDown {
    margin-left: 3%;
    margin-right: 2%;
  }
  .infoFormBox {
    width: 100%;
  }
  .header {
    font-size: 12px;
  }
  .btnRemove {
    position: absolute;
    right: 3%;
  }
  .inputShort select {
    height: 28px;
  }
  .noticeBtn {
    width: 200px;
    margin-top: 40px;
  }
}
