.greyBackGround {
  width: 100%;
  height: 100%vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100000;
  padding-bottom: 1000px;
}
.box {
  width: 80%;
  margin: 0 auto;
  margin-top: 20px;
  border-radius: 20px 20px 20px 20px;
  min-width: 320px;
  padding-bottom: 40px;
}
.box__header {
  height: 60px;
  border-radius: 20px 20px 0 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.box__title {
  font-size: 24px;
  font-weight: 500;
}
.btn {
  position: absolute;
  right: 20px;
  height: min-content;
}
.btn button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline-style: none;
  width: 25px;
  height: 25px;
}
.closeImg {
  width: 25px;
  height: 25px;
  margin-top: 0px;
}
.btn svg {
  margin-top: 0px;
}
.itemBox {
  display: flex;
  padding: 0 100px;
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .itemBox {
    display: flex;
    padding: 0 20px;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .box {
    width: 96%;
  }
  .itemBox {
    display: flex;
    padding: 0 20px;
  }
}
@media screen and (max-width: 767px) {
  .box {
    width: 100%;
    min-width: 320px;
    margin-top: 0px;
    border-radius: 0;
  }
  .box__header {
    border-radius: 0;
  }
  .greyBackGround {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100000;
    padding-bottom: 20px;
  }
  .btn {
    position: absolute;
    right: 20px;
  }
  .box__title {
    font-size: 18px;
    font-weight: 500;
  }
  .itemBox {
    display: flex;
    padding: 0px;
  }
  .closeImg {
    width: 20px;
    height: 20px;
    margin-top: 0px;
  }
}
