.form {
  width: 56%;
}
.inputLong {
  margin-bottom: 25px;
}
.btn {
  height: 43px;
  width: 40%;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  margin-top: 150px;
  cursor: pointer;
  outline-style: none;
  font-weight: 500;
}
.btn:hover {
  background-color: var(--hoverColor);
  color: #fff;
  transition-duration: 0.4s;
}
.alertLine {
  text-align: center;
  margin-bottom: 10px;
}
.btnBox {
  text-align: center;
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .form {
    width: 80%;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .form {
    width: 80%;
  }
}
@media screen and (max-width: 767px) {
  .form {
    width: 80%;
  }

  .inputLong {
    margin-bottom: 18px;
  }
  .btn {
    width: 50%;
    font-size: 16px;
  }
}
