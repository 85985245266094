* {
  margin: 0;
  padding: 0;
}

.container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 320px;
}

.box {
  width: 100%;
  margin-top: 200px;
  border-radius: 0px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
}

.box__subHeader {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 0;
  height: 103px;
}

.box__circle {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin-left: 45px;
  margin-right: 17px;
}

.box__title {
  font-size: 35px;
  font-weight: 500;
}

.box__flexBox {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0px;
  padding-top: 20px;
  padding-bottom: 108px;
  padding-left: 10px;
  padding-right: 10px;
}

.instruction {
  box-sizing: border-box;
  width: 98%;
  text-align: center;
  padding: 10px 40px;
  background-color: #f2f2f2;
  line-height: 130%;
  margin-bottom: 20px;
}

.box__innerBox {
  width: 100%;
  overflow: hidden;
  border: 1px solid #cdcdcd;
}

.box__tabBox {
  width: 100%;
  display: flex;
  margin-bottom: 0px;
}

.complete {
  position: absolute;
  bottom: 10px;
  left: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.clickBtn {
  width: 78%;
  border: none;
  border-radius: 50px;
  padding: 5px 0;
  outline-style: none;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

.clickBtn:hover {
  background-color: var(--specialGrey);
  transition: 0.4s;
}

.seperateBox {
  width: 98%;
}

.upperTableBox {
  margin: 15px 0px;
  border-radius: 10px;
  filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.2));
}

.lowerTableBox {
  border-radius: 10px;
  filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.2));
}

@media screen and (min-width: 1200px) {}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .box {
    width: 98%;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .box {
    width: 100%;
    margin-top: 230px;
    border-radius: 0px;
  }

  .box__subHeader {
    border-radius: 0;
    height: 103px;
  }

  .box__innerBox {
    width: 100%;
    border-radius: 0px;
  }

  .box__flexBox {
    border-radius: 0px;
  }

  .seperateBox {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .box {
    width: 100%;
    margin-top: 44px;
    border-radius: 0px;
  }

  .box__subHeader {
    border-radius: 0;
    height: 103px;
  }

  .box__innerBox {
    width: 100%;
    border-radius: 0px;
  }

  .box__circle {
    width: 16px;
    height: 16px;
    margin-left: 20px;
  }

  .box__title {
    font-size: 20px;
    font-weight: 500;
  }

  .box__flexBox {
    border-radius: 0px;
    padding-top: 10px;
  }

  .box__tabBox {
    height: 70px;
  }

  .seperateBox {
    width: 100%;
  }

  .instruction {
    width: 100%;
    padding: 10px 10px;
    margin-bottom: 10px;
  }
}