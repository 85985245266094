.numberBox {
  display: flex;
  align-items: center;
}
.input {
  width: 50px;
  height: 33px;
  border-left: none;
  border-right: none;
  border: 1px solid var(--mainColor);
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  outline-style: none;
}
.input::-webkit-inner-spin-button,
.input::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
