.container {
  flex: 1;
  margin-top: 2px;
}
.labelBox {
  width: 100%;
}
.text {
  font-size: 14px;
  font-weight: 500;
}
.selectBox {
  width: 100%;
}
.select {
  width: 100%;
  margin-top: 8px;
  box-sizing: border-box;
  border-radius: 100px;
  border: 1px solid var(--specialGrey);
  height: 34px;
  overflow: hidden;
  width: 100%;
  outline-style: none;
  margin-top: 8px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 16px;
}

.select option {
  font-size: 14px;
  color: var(--darkGrey);
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
}
@media screen and (min-width: 768px) and (max-width: 991px) {
}
@media screen and (max-width: 767px) {
  .select {
    font-size: 14px;
  }
}
