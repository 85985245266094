.box {
  flex: 1;
  /* overflow-x: auto; */
  padding-top: 3px;
  margin-bottom: 10px;
}

.container {
  width: 100%;
}
.table {
  /* box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2); */
}

.tableHeader {
  width: 100%;
  height: 74px;
}

.hoverPointer {
  cursor: pointer;
}

.tableHeader__item {
  font-size: 15px;
  font-weight: 500;
  width: 12.5%;
}

.itemBox {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}

.triangleBox {
  margin-left: 8%;
  margin-right: 10%;
  left: 80px;
  bottom: 37%;
  display: flex;
  flex-direction: column;
}

.triangleUp {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 7px solid #6c6c6c;
  margin-bottom: 9px;
  opacity: 0.4;
}

.triangleUpGc {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 7px solid #282828;
  margin-bottom: 9px;
  opacity: 0.4;
}

.triangleDownGc {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 7px solid #282828;
  opacity: 0.4;
}

.triangleDown {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 7px solid #6c6c6c;
  opacity: 0.4;
}

.triangleActive {
  border-bottom-color: white;
  border-top-color: white;
  opacity: 1;
}

.tableTitleBox {
  background-color: #fff;
  width: 140px;
  text-align: center;
  height: 35px;
  margin-left: 3px;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
}

table tr:last-child td:last-child {
  border-radius: 0px 0px 10px 0px;
}

table tr:last-child td:first-child {
  border-radius: 0px 0px 0px 10px;
}

.borderBox {
  border-radius: 0px 0px 10px 10px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  /* overflow: hidden; */
}

@media screen and (min-width: 1200px) {
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .tableHeader__item {
    font-size: 14px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .tableHeader__item {
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  .tableHeader__item {
    font-size: 14px;
  }

  /* .box table {
    width: 1200px !important;

  } */

  .tableTitle {
    font-size: 13px;
  }
}
