.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.wrapper h2 {
  font-size: 20px;
  font-weight: 500;
  margin-top: 40px;
  margin-bottom: 5px;
  color: var(--superDarkGrey);
}
.wrapper p {
  margin-bottom: 20px;
  display: block;
  color: var(--superDarkGrey);
}
.description {
  font-size: 14px;
  text-align: center;
  font-weight: 500;
}
.wrapper p b {
  font-size: 16px;
}
.wrapper h4 {
  font-weight: 700;
  margin-bottom: 20px;
  font-size: 16px;
}
.container {
  /* box-shadow: 2px 2px 3px 4px; */
  box-shadow: 0px 2px 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  padding: 20px;
  width: 86%;
}
.numSelectorLabel {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 8px;
  display: block;
}
.numSelector {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 2rem;
  align-items: center;
}
.numSelector button {
  background-color: var(--mainColor);
  border: none;
  outline: none;
  height: 100%;
  text-align: center;
  cursor: pointer;
  width: 2.25rem;
  font-size: large;
  color: white;
}
.numSelector input {
  border: 1px solid var(--mainColor);
  height: 100%;
  width: 2rem;
  box-sizing: border-box;
  text-align: center;
}
.numSelector button:first-child {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}
.numSelector button:last-child {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}