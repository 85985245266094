.header__container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.nav__button svg {
  margin: 0;
  height: 12px;
  width: 12px;
}
.nav__button {
  margin: 0;
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
  border: none;
  background: #ffffff;
}
@media screen and (min-width: 1200px) {
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
}

@media screen and (min-width: 768px) and (max-width: 991px) {
}

@media screen and (max-width: 767px) {
}
