.label {
  font-size: 18px;
  display: flex;
  flex-direction: column;
}
.select {
  display: flex;
  height: 34px;
  width: 100%;
  border: none;
  border-bottom: 2px solid var(--lightGrey);
  outline-style: none;
  font-size: 15px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url(../../assets/dropDown.svg) no-repeat center right;
  color: var(--darkGrey);
  background-color: rgb(245, 245, 245);
  padding-left: 10px;
}
.select option {
  color: var(--darkGrey);
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .label {
    font-size: 16px;
  }
  .select {
    height: 30px;
    width: 100%;
    font-size: 14px;
  }
}
@media screen and (max-width: 767px) {
  .label {
    font-size: 16px;
  }
  .select {
    height: 30px;
    width: 100%;
    font-size: 14px;
  }
}
