.infoBox {
  padding: 30px 49px 30px;
  border: 2px solid var(--darkGrey);
  border-radius: 20px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 0px;
}
.title {
  font-size: 24px;
  margin-bottom: 7px;
  text-align: center;
}
.text {
  text-align: center;
  font-size: 14px;
  margin-bottom: 36px;
}
.tableBox {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  margin-bottom: 20px;
}
.tableBox th {
  padding-right: 10px;
  font-weight: 500;
}
.tableBox tr {
  margin-bottom: 26px;
}
.btnDownload {
  width: 254px;
}
.btnDownload p {
  text-align: center;
}
.btnPrint {
  width: 254px;
  margin-bottom: 20px;
}
.passNum {
  list-style: none;
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .tableBox th {
    display: flex;
    padding-right: 10px;
    font-weight: 500;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .btnPrint {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .btnDownload {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 767px) {
  .title {
    font-size: 20px;
  }
  .tableBox th {
    font-size: 14px;
    display: flex;
  }
  .tableBox tr {
    font-size: 14px;
  }
  .btnPrint {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .btnDownload {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
