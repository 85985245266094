* {
    margin: 0;
    padding: 0;
}

.container {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-width: 320px;
}

.box {
  width: 82%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 150px;
  border-radius: 20px;
  padding-bottom: 40px;
  max-width: 800px;
}

.textBox {
  display: flex;
  align-items: center;
}

.subHeader {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 103px;
  border-radius: 20px 20px 0 0;
  padding-right: 20px;
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin-left: 45px;
  margin-right: 17px;
}

.subHeaderTitle {
  font-size: 35px;
  font-weight: 500;
  margin: 0;
}
.passForm {
  width: 100%;
  padding: 0px 0px;
}

.mainBox {
  margin: 20px auto;
}

.innerBox {
  display: flex;
  margin-top: 20px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #EFEFEF;
}

.innerBox .column {
  flex: 0 0 50%;
}

.innerBox .column .title {
  flex: 0 0 50%;
}

.hostDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hostDetails button {
  padding: 6px 16px;
  color: #000;
  border: 1px solid #000;
  border-radius: 10px;
}

.title {
  margin-bottom: 10px;
  font-weight: 600;
}

.mainBox div:first-child {
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .box {
    margin-top: 130px;
    width: 100%;
  }
  .passForm {
    max-width: 90%;
  }
  .subHeaderTitle {
    font-size: 25px;
    font-weight: 500;
  }

  .subHeader {
    height: 75px;
  }

  .innerBox {
    flex-wrap: wrap;
  }

  .innerBox .column {
    flex: 0 0 100%;
  }
}