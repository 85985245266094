.flexBox {
  width: 100%;
  display: flex;
  justify-content: center;
}
.passBox {
  width: 50%;
  display: flex;
  justify-content: center;
}
.passBox img {
  width: 100%;
}

.passForm {
  width: 50%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.innerBox {
  width: 70%;
  min-width: 250px;
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .flexBox {
    flex-direction: column;
  }
  .passBox {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .passBox img {
    width: 100%;
  }
  .passForm {
    width: 100%;
    order: 1;
  }
}
@media screen and (max-width: 767px) {
  .flexBox {
    flex-direction: column;
  }
  .passBox {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .passBox img {
    width: 100%;
  }
  .passForm {
    width: 100%;
    order: 1;
  }
}
