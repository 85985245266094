.infoBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  font-size: 20px;
  margin-bottom: 5px;
  font-weight: 500;
}
.subTitle {
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 30px;
}
.infoFormBox {
  width: 80%;
}
.header {
  height: 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  position: relative;
}
.inputShortBox {
  display: flex;
  justify-content: space-between;
}
.inputShort {
  width: 48%;
  margin-top: 10px;
}
.inputLong {
  margin-top: 16px;
}
.numberBox {
  display: flex;
  align-items: center;
  margin-top: 30px;
  justify-content: space-between;
}
.btnDown {
  margin-left: 16px;
  margin-right: 18px;
}
.btnRemove {
  font-size: 12px;
  position: absolute;
  right: 16px;
}
.numberTitle {
  font-size: 18px;
  font-weight: 500;
}
.rentalAddress label {
  font-size: 14px;
  font-weight: 500;
}
.rentalAddress input {
  box-sizing: border-box;
  border-radius: 100px;
  border: 1px solid var(--specialGrey);
  height: 34px;
  overflow: hidden;
  width: 100%;
  outline-style: none;
  margin-top: 8px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 17px;
  text-indent: 4px;
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .inputShortBox {
    flex-direction: column;
  }
  .inputShort {
    width: 100%;
    margin-top: 10px;
  }
  .btnDown {
    margin-left: 3%;
    margin-right: 5%;
  }
  .numberBox {
    flex-direction: column;
  }
  .numberTitle {
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
}
@media screen and (max-width: 767px) {
  .inputShortBox {
    flex-direction: column;
  }
  .inputShort {
    width: 100%;
    margin-top: 10px;
  }
  .btnDown {
    margin-left: 3%;
    margin-right: 5%;
  }
  .numberBox {
    flex-direction: column;
  }
  .numberTitle {
    margin-bottom: 10px;
  }
  .rentalAddress input {
    height: 28px;
    margin-top: 8px;
    font-size: 14px;
  }
  .rentalAddress label {
    font-size: 14px;
    font-weight: 500;
  }
}
