.sheet {
  height: 73px;
}

/* .pointerHover {
  cursor: pointer;
} */

.item {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid rgb(230, 230, 230);
}
.completeBtn {
  padding: 4px 10px;
  border: 1px solid var(--superDarkGrey);
  border-radius: 10px;
  font-weight: 500;
  background-color: #fff;
  cursor: pointer;
}
