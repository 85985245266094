* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.container {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-width: 320px;
}
.box {
  width: 82%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 150px;
  border-radius: 20px;
  padding-bottom: 40px;
  max-width: 800px;
}
.heading h1 {
    font-size: 35px;
}

.heading p {
    font-size: 16px;
}

.passButton button {
    margin-top: 30px;
    background: var(--mainColor);
    border: none;
    border-radius: 5px;
    padding: 6px 10px;
}

.outlineButton button {
    border-radius: 5px;
    background-color: transparent;
    border: 1px solid #000;
    color: #000;
    padding: 6px 15px;
    display: flex;
    align-items: center;
}

.innerBox {
  margin: 20px 0px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #EFEFEF;
}

.outlineButton button svg {
    height: 17px;
    margin-right: 4px;
}

.cardCol {
    max-width: 50%;
    flex: 0 0 50%;
    padding: 0px 10px;
}

.cardColFull {
    max-width: 100%;
    flex: 0 0 100%;
    padding: 0px 10px;
}

.cardItem,
.cardFull {
    padding: 10px;
    border: 1.5px solid #EFEFEF;
    border-radius: 4px;
    margin-bottom: 20px;
}

.cardItem p {
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 700;
}

.cardItem p span {
    font-weight: normal;
}

.cardRow {
    margin: 40px -10px 0px;
    display: flex;
    flex-wrap: wrap;
}

.cardRow button {
    width: 100%;
    text-align: center;
    justify-content: center;
}

.cardItem img {
    width: auto;
}

.col6 {
    max-width: 50%;
    flex: 0 0 50%;
    padding: 0px 10px;
}

.row {
    margin: 0px -10px;
    display: flex;
    flex-wrap: wrap;
}

.hostdetails {
    display: flex;
    justify-content: space-between;
}

.passHeader h1 {
    font-size: 18px;
    margin-bottom: 5px;
}

.passHeader h1 span {
    font-weight: normal;
}

.cardFull {
    margin-top: 30px;
    font-size: 16px;
}

.cardFull .outlineButton {
    float: right;
    text-align: right;
    flex: 0 0 auto;
}

.cardFull h5 {
    margin-bottom: 10px;
    font-size: 14px;
}

.textBox {
    display: flex;
    align-items: center;
}

.subHeader {
    /* position: relative; */
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 103px;
    border-radius: 20px 20px 0 0;
    padding-right: 20px;
}

.circle {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    margin-left: 45px;
    margin-right: 17px;
}

.subHeaderTitle {
    font-size: 35px;
    font-weight: 500;
    margin: 0;
}
.subHeaderNote {
  font-size: 16px;
  margin: 10px 0 20px 0;
  font-weight: normal;
}
.subHeaderNote p{
  margin-bottom: 10px;
}
.passForm {
  width: 100%;
  padding: 0px 0px;
}

.passesImgRow {
  display: flex;
  flex-wrap: wrap;
  margin: 0px -10px;
  text-align: center;
}

.passesImg {
  flex: 0 0 calc(100% / 2);
  padding: 0px 10px;
}

.passImage1 {
  flex: 0 0 calc(100% / 1);
  padding: 0px 10px;
}
.button {
  padding: 15px 20px;
  /* Increases the size of the button */
  font-size: 20px;
  /* Increases the font size */
  background-color: #115A84;
  /* Changes the button color */
  color: white;
  /* Button text color */
  border: none;
  border-radius: 10px;
  /* Optional: Rounds the corners */
  cursor: pointer;
}
@media screen and (min-width: 1200px) {}

@media screen and (min-width: 992px) and (max-width: 1199px) {}

@media screen and (min-width: 768px) and (max-width: 991px) {}

@media screen and (max-width: 767px) {
  .box {
    margin-top: 130px;
    width: 100%;
  }
  .subHeaderTitle {
    font-size: 25px;
    font-weight: 500;
  }

    .cardCol,
    .col6 {
        max-width: 100%;
        flex: 0 0 100%;
        padding: 0px 10px;
    }

    .col6 {
        margin-bottom: 10px;
    }

    .heading h1 {
        font-size: 25px;
    }

    .heading p {
        font-size: 14px;
    }

    .cardItem p {
        font-size: 14px;
    }

    .innerBox {
        max-width: 90%;
        margin: 20px auto;
    }
    .passForm {
      max-width: 90%;
    }
}
