.form {
  width: 56%;
}
.inputBox {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}
.inputLong {
  margin-bottom: 25px;
}
.inputShort {
  width: 48%;
}
.alert {
  font-size: 14px;
  text-align: center;
  margin-left: 8px;
}
.alertBox {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn {
  height: 43px;
  width: 40%;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  margin-top: 43px;
  cursor: pointer;
  outline-style: none;
  font-weight: 500;
}
.btnBox {
  text-align: center;
}
.btn:hover {
  background-color: var(--hoverColor);
  color: #fff;
  transition-duration: 0.4s;
}
.alertLine {
  text-align: center;
  margin-bottom: 10px;
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .form {
    width: 80%;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .form {
    width: 80%;
  }
}
@media screen and (max-width: 767px) {
  .form {
    width: 80%;
  }
  .inputBox {
    flex-direction: column;
    margin-bottom: 0px;
  }
  .inputShort {
    width: 100%;
    margin-bottom: 18px;
  }
  .inputLong {
    margin-bottom: 18px;
  }
  .btn {
    width: 50%;
    font-size: 16px;
  }
}
