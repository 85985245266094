* {
  margin: 0;
  padding: 0;
}
.container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 320px;
}

.container__innerContainer {
  display: flex;
  flex: 0.9;
  flex-direction: column;
  margin-top: 200px;
  text-align: center;
}
.notifyIncomplete {
  text-align: center;
  padding: 5px;
  border: 1px solid #b53041;
  width: fit-content;
  margin: 0 auto;
  border-radius: 5px;
  color: #b53041;
  background-color: #f5d2d7;
  font-weight: 500;
  cursor: pointer;
}
.box {
  display: flex;
  flex: 0.96;
  justify-content: center;
  flex-wrap: wrap;
}

.box__innerBox {
  margin: 10px 10px;
  flex: 0.2;
  min-width: 230px;
}
.popUpInnerBox {
  padding: 20px 40px;
}
.notification {
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  padding: 5px;
  border: 1px solid #b53041;
  margin: 0 auto;
  border-radius: 5px;
  color: #b53041;
  background-color: #f5d2d7;
  font-weight: 500;
  margin-bottom: 8px;
}

@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .box {
    flex-wrap: wrap;
    /* margin-top: 200px; */
  }
  .container__innerContainer {
    flex: 0.9;
    margin-top: 230px;
  }
  .box__innerBox {
    margin: 10px 10px;
  }
  .box__innerBox {
    flex: 0.1;
  }
}

@media screen and (max-width: 767px) {
  .box {
    flex-direction: column;
    min-width: 320px;
    flex: 0.6;
    margin-bottom: 20px;
    /* margin-top: 64px; */
  }
  .container__innerContainer {
    flex: 0.9;
    margin-top: 64px;
  }
  .box__innerBox {
    margin-top: 10px;
  }

  .box__innerBox {
    margin: 10px 30px;
  }
  .popUpInnerBox {
    box-sizing: border-box;
    padding: 20px 0px;
  }
  .notification {
    box-sizing: border-box;
    border-radius: 0px;
  }
}
