.box {
  margin-top: 160px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.videoContainer {
  position: relative;
  padding-bottom: 83%;
  height: 0;
  width: 50%;
  overflow: hidden;
}
.videoContainer iframe,
.videoContainer object,
.videoContainer embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .box {
    margin-top: 240px;
  }
}
@media screen and (max-width: 767px) {
  .box {
    margin-top: 44px;
    min-width: 320px;
  }
  .videoContainer {
    width: 90%;
  }
}
