* {
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
}
.cardBox {
  flex: 1;
}
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 20px;
  height: 350px;
  min-width: 230px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
  padding: 0 1px;
}

.card__title {
  flex: 0.14;
  font-size: 18px;
  font-weight: 600;
  margin-top: 21px;
}

.card__description {
  font-size: 14px;
  font-weight: 600;
  max-width: 270px;
  height: 40px;
  margin-top: 4px;
  line-height: 150%;
}

.card__btn {
  display: flex;
  width: 100%;
  justify-content: center;
  flex: 0.41;
  margin-top: 60px;
}
.card__btnInnerBox {
  display: flex;
  flex: 0.8;
}
.card svg {
  margin-top: 20px;
  flex: 0.33;
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .card {
    height: 300px;
  }
  svg {
    margin-top: 5px;
    width: 90px;
  }
  .card__title {
    margin-top: 5px;
  }
  .card__btn {
    margin-top: 40px;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .card {
    height: 280px;
  }
  .card__btn {
    margin-top: 20px;
  }
  .card__title {
    margin-top: 0px;
  }
  .card__description {
    font-size: 12px;
    max-width: 200px;
  }
  svg {
    margin-top: 20px;
    width: 80px;
  }
}

@media screen and (max-width: 767px) {
  .card {
    height: 280px;
    border-radius: 20px;
  }
  .card__btn {
    margin-top: 40px;
  }
  .card__description {
    font-size: 12px;
    max-width: 200px;
  }
  .card__title {
    margin-top: 5px;
  }
  svg {
    margin-top: 5px;
    width: 80px;
    height: 100px;
  }
}
