.tabBox {
  flex: 0 0 auto;
}

.tabBox__tab {
  position: relative;
  width: 100%;
  border: none;
  border-radius: 20px 20px 0 0;
  outline-style: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  background: #eee;
  border-radius: 0px;
  background: #FFF;
  box-shadow: 0px -4px 20px 0px rgba(130, 141, 157, 0.08);
}

.tabBox .active {
  color: #CECACA;
  background: #F1F1F1;
  box-shadow: 0px -4px 20px 0px rgba(130, 141, 157, 0.08);
}

.tabBox__title {
  font-size: 14px;
}

.tabBox__tabLine {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2px;
}

@media screen and (min-width: 1200px) {}

@media screen and (min-width: 992px) and (max-width: 1199px) {}

@media screen and (min-width: 768px) and (max-width: 991px) {}

@media screen and (max-width: 767px) {
  .tabBox {
    display: flex;
  }
}