.greyBackGround {
  width: 100%;
  height: 100%vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  padding-bottom: 1000px;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
}
.box {
  max-height: 80vh;
  overflow: auto;
  margin-top: 100px;
  width: 50%;
  border-radius: 5px;
  scroll-behavior: smooth;
  overscroll-behavior: contain;
}
.box__header {
  border-radius: 5px 5px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 14px;
}
.headerMiddle {
  display: flex;
  justify-content: center;
  padding: 10px 14px;
}
.box__title {
  font-weight: 500;
  font-size: 16px;
  margin: 0px 5px;
}
.box__titleLeft {
  display: flex;
  align-items: center;
}
.circle {
  min-width: 16px;
  height: 16px;
  border-radius: 16px;
  background-color: #fff;
  margin-right: 5px;
}
.btn button {
  background-color: transparent;
  border: none;
}
.btn button svg {
  margin: 0;
  width: 20px;
}
.box__titleMiddle {
}

@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .box {
    margin-top: 40px;
    width: 100%;
    border-radius: 0px;
  }
  .box__header {
    border-radius: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
  }
}
@media screen and (max-width: 767px) {
  .box {
    margin-top: 0px;
    width: 100%;
    border-radius: 0px;
  }
  .box__header {
    border-radius: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
  }
}
