.infoBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  font-size: 20px;
  margin-bottom: 5px;
  font-weight: 500;
}
.subTitle {
  font-size: 14px;
  text-align: center;
  font-weight: 500;
}
.collectionBox {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.btnText {
  font-size: 14px;
  font-weight: 500;
}
.btnBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.btnText {
  margin-top: 1px;
}
.btn {
  width: 254px;
  margin-top: 20px;
}
.btnBox button {
  display: flex;
  align-items: center;
  width: 100px;
  height: 25px;
  outline-style: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
}
.addBtnImg {
  width: 25px;
  height: 25px;
  fill: var(--secondaryColor);
  margin-top: 0px;
  margin-right: 5px;
}
.addBtnBox {
  align-items: center;
  color: var(--mainColor);
}
.addBtnBox button {
  color: var(--mainColor);
}
.passTypeTable {
  width: 300px;
}

@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .btn {
    width: 200px;
    margin-top: 40px;
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 767px) {
  .btn {
    width: 200px;
    margin-top: 25px;
    display: flex;
    justify-content: center;
  }
  .collectionBox {
    margin-top: 15px;
  }
  .btnBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
  }
}
