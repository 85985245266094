.infoBox {
  padding: 30px 49px 30px;
  border: 2px solid var(--darkGrey);
  border-radius: 20px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 0px;
}
.summaryBox {
  width: 100%;
}
.totalBox {
  height: 50px;
  width: 100%;
  border: 2px solid var(--darkGrey);
  border-radius: 20px;
  display: flex;
  align-items: center;
}
.total {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.textLeft {
  font-size: 20px;
  margin-left: 5%;
  font-weight: 500;
  line-height: 65px;
}
.textRight {
  font-size: 20px;
  margin-right: 5%;
  font-weight: 500;
  line-height: 65px;
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
}
@media screen and (min-width: 768px) and (max-width: 991px) {
}
@media screen and (max-width: 767px) {
  .totalBox {
    height: 40px;
  }
  .textLeft {
    font-size: 20px;
  }
  .textRight {
    font-size: 20px;
  }
}
