.container {
  height: 80vh;
  overflow: scroll;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.box {
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
  margin-bottom: 30px;
  border-radius: 20px;
  max-width: 500px;
}
.header {
  position: relative;
  height: 40px;
  border-radius: 20px 20px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.exit {
  position: absolute;
  right: 15px;
}
.title {
  margin-left: 2px;
  font-weight: 500;
  font-size: 16px;
}
.infoBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px;
  padding-left: 10px;
}
.text {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 27px;
}
.inputLongBox {
  display: flex;
}
.inputBox {
  display: flex;
  justify-content: space-between;
  margin-bottom: 26px;
}
.inputShort {
  width: 40%;
  margin-bottom: 13px;
}

.checkBox {
  width: 22px;
  height: 22px;
  border: 2px solid #d8d8d8;
  margin-right: 10px;
  background-color: #fff;
  -webkit-appearance: none;
  outline-style: none;
  cursor: pointer;
}
.checkBox:checked {
  background: url('../../assets/checked.svg') no-repeat center;
}

.inputLong {
  width: 100%;
  margin-bottom: 26px;
}
.btn {
  width: 80%;
}
.addBtn {
  margin-top: 30px;
  text-align: center;
}
.textBelow {
  font-size: 14px;
  font-weight: 500;
}
.addBtn button {
  width: 25px;
  height: 25px;
  outline-style: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
}
.addBtnImg {
  width: 25px;
  height: 25px;
  fill: var(--secondaryColor);
  margin-top: 0px;
}
.exit button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline-style: none;
  /* background: url(../../assets/close.svg) no-repeat center; */
  width: 20px;
  height: 20px;
}
.exitBtnSmallImg {
  width: 20px;
  height: 20px;
  margin-top: 0px;
}
.exitHidden {
  display: none;
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .inputBox {
    flex-direction: column;
    margin-left: 30px;
  }
  .inputShort {
    width: 100%;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .btn {
    text-align: center;
  }
}
@media screen and (max-width: 767px) {
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .btn {
    text-align: center;
    display: flex;
    justify-content: center;
  }
}
