* {
    margin: 0;
    padding: 0;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120vh;
    min-width: 320px;
}

.box {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 82%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;
}

.box1 {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 82%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 130px 0px;
}

.mainBox {
    text-align: center;
}

.innerBox {
    padding: 20px;
}

/* Loading animation styles */
.loading {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #000;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}