.wrapper {
  position: relative;
}

.defaultInput {
  box-sizing: border-box;
  border-radius: 100px;
  border: 1px solid var(--specialGrey);
  height: 34px;
  overflow: hidden;
  width: 100%;
  outline-style: none;
  margin-top: 8px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 17px;
  text-indent: 4px;
  color: var(--mediumGrey);
}

.dropdown {
  position: absolute;
  background-color: white;
  z-index: 10;
  border-style: solid;
  border-width: 1px;
  border-color: var(--specialGrey);
  border-radius: 1rem;
  min-width: max-content;
  box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.3);
}

.dropdownHead {
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.25rem;
}

.dropdownTable {
  border-collapse: collapse;
  width: 100%;
}

.dropdownTable th,
.dropdownTable td {
  padding: 0.5rem;
  border-style: solid;
  border-width: 0px;
  border-color: var(--specialGrey);
  border-top-width: 1px;
}

.dropdown tr:last-child>td:first-child {
  border-bottom-left-radius: 1rem;
}

.dropdown tr:last-child>td:last-child {
  border-bottom-right-radius: 1rem;
}

.dropdownTable,
.dropdownTable tbody,
.selectableRow:last-child {
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.selectableRow:hover {
  background-color: var(--secondaryColor);
}

.emptyState {
  width: 100%;
  min-width: max-content;
  text-align: center;
  padding: 0.5rem 0px;
  border-style: solid;
  border-width: 0px;
  border-color: var(--specialGrey);
  border-top-width: 1px;
}

.clear {
  width: 100%;
  min-width: max-content;
  text-align: center;
  padding: 0.25rem 0px;
  border-style: solid;
  border-width: 0px;
  border-color: var(--specialGrey);
  border-top-width: 1px;
}