.title {
  font-size: 18px;
  font-weight: 500;
}
.box {
  margin-bottom: 54px;
}
.titleBox {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container {
  padding-bottom: 20px;
  border-bottom: 2px solid var(--specialGrey);
}
.tHead {
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  min-width: 100px;
  word-break: break-all;
}

.tableBox {
  width: 100%;
}
.table {
  width: 100%;
}
.trBox {
  width: 100%;
  text-align: left;
}
.subtotal {
  font-size: 18px;
  font-weight: 500;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  padding-right: 25px;
}
.vehicleInfoBox {
  margin-top: 20px;
}
.tHeadSpace {
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  min-width: 100px;
  padding-top: 10px;
}
.editBtn button {
  width: 39px;
  height: 39px;
  border-radius: 1000px;
  border: 2px solid #fff;
  font-size: 24px;
  cursor: pointer;
  outline-style: none;
  background-color: var(--thirdColor);
}
.circleImg {
  margin-top: 0px;
  width: 16px;
  height: 16px;
}
.info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.ulBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  list-style: none;
  /* width: 50%; */
}
.info ul:nth-child(odd) {
  width: 70%;
}
.info ul:nth-child(even) {
  width: 30%;
}

@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
}
@media screen and (min-width: 768px) and (max-width: 991px) {
}
@media screen and (max-width: 767px) {
  .title {
    font-size: 16px;
  }
  .subtotal {
    font-size: 16px;
  }
  .vehicle ul:nth-child(odd) {
    width: 60%;
  }
  .vehicle ul:nth-child(even) {
    width: 40%;
  }
}
