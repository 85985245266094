.box {
  width: 80%;
}

.alertBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 60px;
}

.alertImg {
  width: 54px;
  height: 54px;
  margin-bottom: 20px;
}

.text {
  font-weight: 500;
  text-align: center;
  margin-top: 10px;
}

.btnBox {
  width: 100%;
  display: flex;
  justify-content: center;
}

.cancel {
  width: 254px;
  height: 43px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid var(--mainColor);
  color: var(--mainColor);
  cursor: pointer;
  margin-right: 40px;
}

/* .cancel:hover {
    background-color: var(--hoverColor);
    color: white;
    transition: .4s;
} */
.delete {
  width: 254px;
  height: 43px;
  background-color: var(--mainColor);
  border-radius: 10px;
  color: white;
  cursor: pointer;
  border: none;
}

.delete:hover {
  background-color: var(--hoverColor);
  transition: 0.4s;
}

.deleteText {
  margin-top: 10px;
}

.submitButton {
  margin-left: 382px;
  margin-top: 26px;
}

.amenitySubmitBtn {
  margin-left: 184px;
  margin-top: 26px;
}

.passNumber {
  text-align: center;
  height: auto;
  margin: 14px 44px;
  background-color: black;
  color: white;
  width: auto;
  padding: 5px 5px;
  border-radius: 7px;
}

.details {
  text-align: center;
  height: auto;
  margin: 12px 94px;
  width: auto;
  padding: 5px 5px;
  border-radius: 7px;
  font-size: large;
}

@media screen and (min-width: 1200px) {}

@media screen and (min-width: 992px) and (max-width: 1199px) {}

@media screen and (min-width: 768px) and (max-width: 991px) {}

@media screen and (max-width: 767px) {
  .btnBox {
    flex-direction: column;
    align-items: center;
  }

  .cancel {
    width: 200px;
    height: 35px;
    margin-bottom: 18px;
  }

  .delete {
    width: 200px;
    height: 35px;
  }
}