.shareBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  font-size: 25px;
  font-weight: 500;
  text-align: center;
}
.name {
  width: 50%;
  font-size: 18px;
  font-weight: 500;
  height: 50px;
  background-color: var(--lightGrey);
  margin-bottom: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
}
@media screen and (min-width: 768px) and (max-width: 991px) {
}
@media screen and (max-width: 767px) {
  .title {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
  }
  .name {
    width: 80%;
    font-size: 16px;
    font-weight: 500;
    height: 38px;
    background-color: var(--lightGrey);
    margin-bottom: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 0px 10px;
  }
}
