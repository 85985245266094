.container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 320px;
}

.box {
  border-radius: 20px;
  margin-top: 150px;
  min-width: 320px;
  /* box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25); */
  width: 82%;
  /* padding-left: 2%;
  padding-right: 2%; */
  padding-bottom: 26px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
}

.btn {
  width: 254px;
  margin-top: 20px;
}

.videoBox {
  width: 100%;
  border-radius: 3px;
  border: 1px solid #efefef;
  margin-top: 30px;
}

.videoContainer {
    /* position: relative; */
    /* padding-bottom: 83%;
      height: 0;
      overflow: hidden; */
  display: flex;
  justify-content: center;
}

.videoContainer iframe,
.videoContainer object,
.videoContainer embed {
    /* position: absolute; */
    /* top: 0;
          left: 50%;
          transform: translateX(-50%); */
  width: 50vw;
  height: 65vh;
}

.header {
  font-size: 25px;
  margin-top: 30px;
  margin-bottom: 10px;
  font-weight: 600;
}

.headerwraper {
  width: 100%;
}

.subHeader {
  /* position: relative; */
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 103px;
  border-radius: 20px 20px 0 0;
  padding-right: 20px;
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin-left: 45px;
  margin-right: 17px;
}

.subHeaderTitle {
  font-size: 35px;
  font-weight: 500;
  margin: 0;
}
.subHeaderNote {
  font-size: 16px;
  margin-top: 5px;
}
.innerBox {
  margin: 20px 0px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #EFEFEF;
  width: 100%;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  /* Aligns the button to the right */
  padding: 10px;
  /* Optional: Add some padding if needed */
}
.button {
  padding: 15px 20px;
  /* Increases the size of the button */
  font-size: 20px;
  /* Increases the font size */
  background-color: #115A84;
  /* Changes the button color */
  color: white;
  /* Button text color */
  border: none;
  border-radius: 10px;
  /* Optional: Rounds the corners */
  cursor: pointer;
}
.passForm {
  width: 100%;
  padding: 0px 0px;
}

@media screen and (min-width: 1200px) {}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .box {
    width: 80%;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  
  .btn {
    width: 200px;
    margin-top: 116px;
    display: flex;
    justify-content: center;
  }

  .videoContainer iframe,
  .videoContainer object,
  .videoContainer embed {
    /* position: absolute; */
    /* top: 0;
      left: 50%;
      transform: translateX(-50%); */
    width: 100vw;
    height: 65vh;
  }
}

@media screen and (max-width: 767px) {
  .box {
    margin-top: 130px;
    width: 100%;
  }

  .btn {
    width: 200px;
    margin-top: 25px;
    display: flex;
    justify-content: center;
  }

  .videoContainer {
    position: relative;
    padding-bottom: 110%;
    height: 0;
    overflow: hidden;
  }

  .videoContainer iframe,
  .videoContainer object,
  .videoContainer embed {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
  }
  .buttonBox {
    border: none;
    border-top: 1px solid #EFEFEF;
  }
  .passForm {
    max-width: 90%;
  }
  .subHeaderTitle {
    font-size: 25px;
    font-weight: 500;
  }
  .buttonContainer {
    justify-content: center;
  }
}