.driverFormBox {
  margin-top: 34px;
  margin-bottom: 40px;
}
.title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 17px;
  text-align: center;
}
.inputShortBox {
  display: flex;
  justify-content: space-between;
}
.inputShort {
  width: 48%;
  margin-top: 10px;
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .inputShortBox {
    flex-direction: column;
  }
  .inputShort {
    width: 100%;
    margin-top: 10px;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
}
@media screen and (max-width: 767px) {
  .inputShortBox {
    flex-direction: column;
  }
  .inputShort {
    width: 100%;
    margin-top: 10px;
  }
}
