.infoBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  font-size: 20px;
  margin-bottom: 5px;
  font-weight: 500;
}
.subTitle {
  font-size: 14px;
  text-align: center;
  font-weight: 500;
}
.inputBox {
  padding: 30px 30px 30px;
  border: 2px solid var(--darkGrey);
  border-radius: 20px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.inputShortBox {
  display: flex;
  justify-content: space-between;
}
.inputShort {
  width: 48%;
  margin-bottom: 17px;
}
.inputLong {
  width: 100%;
  margin-bottom: 17px;
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .inputShortBox {
    flex-direction: column;
  }
  .inputShort {
    width: 100%;
    margin-bottom: 17px;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
}
@media screen and (max-width: 767px) {
  .inputShortBox {
    flex-direction: column;
  }
  .inputShort {
    width: 100%;
    margin-bottom: 17px;
  }
  .inputBox {
    width: 100%;
    margin-top: 15px;
    padding: 20px 20px 30px;
  }
}
